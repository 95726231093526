import { Workbox } from "./_snowpack/pkg/workbox-window.js";

// function onServiceWorkerRegistration(reg) {
//   reg.pushManager.getSubscription().then((sub) => {
//     if (sub === null) {
//       // Update UI to ask user to register for Push
//       // alert("Not subscribed to push service!");
//       Notification.requestPermission((status) => {
//         console.log("Notification permission status:", status);
//       });
//     } else {
//       // We have a subscription, update the database
//       // alert(JSON.stringify(sub));
//     }
//   });
// }

(async () => {
  if ("serviceWorker" in navigator && location.protocol.startsWith("https")) {
    window.addEventListener("load", async () => {
      const wb = new Workbox("/service-worker.js");
      const registration = await wb.register();

      // ensure the case when the updatefound event was missed is also handled
      // by re-invoking the prompt when there's a waiting Service Worker
      if (registration.waiting) {
        registration.waiting.postMessage("SKIP_WAITING");
      }
      // detect Service Worker update available and wait for it to become installed
      registration.addEventListener("updatefound", () => {
        if (registration.installing) {
          // wait until the new Service worker is actually installed (ready to take over)
          registration.installing.addEventListener("statechange", () => {
            if (registration.waiting) {
              // if there's an existing controller (previous Service Worker), show the prompt
              if (navigator.serviceWorker.controller) {
                registration.waiting.postMessage("SKIP_WAITING");
              } else {
                // otherwise it's the first install, nothing to do
                console.log("Service Worker initialized for the first time");
              }
            }
          });
        }
      });

      let refreshing = false;

      // detect controller change and refresh the page
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (!refreshing) {
          window.location.reload();
          refreshing = true;
        }
      });
    });
  }
  //
})();
